<template>
    <section class="fixed flex flex-col h-screen min-w-full">
        <div class="">
            <Header bg="bg-brand"/>
        </div>
        <div>
            <div v-if="meals.length" class="grid grid-cols-1 lg:grid-cols-6">
                <div class="p-3 overflow-scroll lg:col-span-4 h-90vh lg:p-6">
                    <VendorBreadcrumb :time="storeDetails.estimatedTime" :reviewsData="reviewsData" :reviews="storeDetails.numberOfReviews" :rating="storeDetails.rating" :header="storeDetails.images[0].image" :logo="storeDetails.logo.image" :name="storeDetails.name" :address="storeDetails.address"/>
                    <div class="my-6">
                        <div class="p-5 bg-gray-100 rounded-lg" >
                            <h1 class="mb-2 font-semibold">Enter your delivery address</h1>
                            <DeliveryAddress name="ResturantPage" />
                        </div>
                    </div>
                    <div class="relative z-10 my-4 md:hidden">
                        <SearchFood :restaurant="storeDetails" />
                    </div>
                    <Categories v-if="categories.length" @sortCategory="sortCategory" :categories="categories"/>
                    <!-- <div v-if="!isInstant" ref="pills" class="flex gap-3 pb-4 mx-3 mt-4 overflow-x-auto lg:overflow-x-hidden lg:mx-16">
                        <h1 @click="sortMeals('all',$event)" ref="" class="pill min-w-35% lg:min-w-0 px-8 active">All</h1>         
                        <h1 v-for="category in sortTimebelt(storeDetails.availableTimeBelts).filter(item => item.name != 'Instant' )" @click="sortMeals(category.name,$event)" :key="category"  class="pill min-w-35% lg:min-w-0 px-6">{{ category.name.toLowerCase() }}</h1>         
                    </div> -->
                    <div class="mb-14 lg:overflow-auto scrollbar scrollbar-thumb-rounded-full scrollbar-thin scrollbar-thumb-gray-100 scrollbar-track-transparent scrollbar-track-rounded-full" >
                        <div v-if="sortedMeals.length > 0" class="mt-3 lg:my-6">
                            <Food v-for="meal in sortedMeals" :details="storeDetails" :id="storeDetails.restaurantId" :key="meal" :data="meal" :isInstant="isInstant" />
                            <div v-if="page + 1 != totalPage" class="flex justify-center flex-grow my-6">
                                <div class="flex items-center gap-3">
                                    <button @click="loadMore" class="px-8 py-4 text-sm rounded-md bg-brand">
                                        <Preloader v-if="showLoadmorePreloader"/>
                                        <span v-else >Load more</span>    
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div v-else class="flex items-center justify-center h-400">
                            <div>
                                <div class="flex items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-14 w-14" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                                <p class="text-sm text-center">There is no meal available for this timeBelt </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hidden col-span-2 border-l lg:block">
                    <div class="hidden lg:block" >
                        <div class="p-4 bg-white mb-14 lg:mt-3">
                            <Cart :isInstant="isInstant" :minimumOrderCost="minimumOrderCost" @showContainerPopup="toogleShowContainerPopup" @changeTimebelt="toogleModal" :Total="getCost" :allowBookingPortion="allowBookingPortion" :getCart="getCart" :restaurant="storeDetails" :minTime="minTime" :maxTime="maxTime" />
                        </div>
                    </div>  
                </div>
            </div>
            <div v-else class="flex items-center justify-center h-screen space-x-4 bg-gray-50">
                <div class="block">
                    <div class="flex justify-center mb-2" >
                        <Preloader/>
                    </div>
                    <p class="text-sm">Loading meals ....</p> 
                </div>  
            </div>
        </div>
    </section>
    <div class="fixed top-0 right-0 z-50 flex items-center lg:hidden mscreen" >
        <div @click="toggleMobileCart" class="relative p-3 text-gray-100 bg-black rounded-l shadow-lg" >
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
            </svg>
            <div  class="absolute flex items-center justify-center w-4 h-4 text-xs text-gray-100 bg-red-500 rounded-full top-2 right-1" >
                {{ getCart.cartItems.length + getCart.containers.length}}
            </div>
        </div> 
        <MobileCart v-if="showMobileCart" :minimumOrderCost="minimumOrderCost" @showContainerPopup="toogleShowContainerPopup" :allowBookingPortion="allowBookingPortion" :Total="getCost" @changeMobileTimebelt="toogleModal"  @closeNav="toggleMobileCart" :cartLenght="getCart.cartItems.length + getCart.containers.length" :isInstant="isInstant" :restaurant="storeDetails" :minTime="minTime" :maxTime="maxTime"/> 
    </div>

    <Modal v-if="showModal" @modalChanges="modalChanges" :currentDate="currentDate"  :isInstant="storeDetails.bookingType==='INSTANT'" :id="storeDetails.restaurantId" :timebelts="storeDetails.availableTimeBelts"/>
    <Modal :show=true type="large" width="w-500" v-if="showAddress">
        <h1 class="font-semibold">Sorry we couldn't get your delivery address</h1>
        <div v-if="error" class="my-2 text-red-500" >{{ error }}</div> 
        <div>
            <label for=""></label>
            <vue-google-autocomplete 
                ref="address"
                id="map"
                v-on:placechanged="getAddressData"
                v-model="inputAddress" 
                country="ng"
                classname="input" 
                placeholder="Enter Delivery Address">
            </vue-google-autocomplete>
        </div>
        <div class="flex justify-end flex-grow mt-4 space-x-2">
            <button @click="checkRestaurant"  type="submit" ref="submit" class="px-3 py-2 text-sm font-semibold rounded bg-brand">    
                Use
            </button>
        </div>
    </Modal>
    <Container :showContainerPopup="showContainerPopup" @closeContainerModal="toogleShowContainerPopup" :storeContainers="storeDetails.foodContainers"/> 
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import VendorBreadcrumb from "@/components/VendorBreadcrumb.vue";
import BreadCrumb from "@/components/BreadCrumb.vue";
import Cart from "@/components/UpdatedComponents/Cart.vue";
import Food from "@/components/FoodCard.vue";
import DeliveryAddress from "@/components/DeliveryAddress.vue";
import axios from "axios"
import Modal from "@/components/Modal.vue"
import Preloader from "@/components/Preloader.vue";
import MobileCart from "@/components/MobileCart.vue"
import Container from "@/components/Container.vue"
import moment from "moment";
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import Categories from "@/components/Categories.vue";
import SearchFood from '@/components/SearchFood.vue';

export default {
    name: 'ResturantPage',
    components:{
        Header,Footer,MobileCart,VendorBreadcrumb,Cart,Food,DeliveryAddress,BreadCrumb,Modal,Preloader,VueGoogleAutocomplete,Container,Categories,SearchFood
    },
    emits:['changeTimebelt','modalChanges','closeNav','changeMobileTimebelt'],
    data(){
        return{
            name: this.$route.params.vendorName,
            timebelt: 'scheduled',
            storeDetails: "",
            meals: [],
            minTime:"",
            maxTime:"",
            isInstant: false,
            sortedMeals:[],
            portionMeals:[],
            getnewTimebelt : this.$store.getters.getTimebelt,
            search:"",
            showModal:false,
            showMobileCart:false,
            watchTime:"",
            subTotalCost:0,
            showAddress:false,
            inputAddress:"",
            error:null,
            reviewsData:[],
            currentDate:"",
            minimumOrderCost:"",
            showContainerPopup:false ,
            arrangeContainer:false,
            containers:[],
            allowBookingPortion:false,
            restaurantContainers:[
                { name:"Small Container",amount:100 },
                { name:"Meduim Container",amount:100 },
                { name:"Large Container",amount:100 }
            ],
            page:0,
            size:10,
            showLoadmorePreloader:false,
            categories:[]
        }
    },
    methods:{
        async getResturantsDetails(){
            //Get  Resturants from Api
            let urlParams = this.$route.params.publicUrl;
            let url = this.$store.state.base_url + 'restaurant/public/'+ urlParams;
            try {
                let res = await this.axios.post(url,this.$store.getters.getAddress)
                this.storeDetails = res.data
                this.$store.dispatch('saveStoreDetail',this.storeDetails)
            } catch (err) {
                if(err.response.data.statusCode == '404') {
                    this.error = err.response.data.message
                    this.toogleAddress();
                }   
            }
        },
        sortCategory(data){
            if(data == 'all'){
                this.sortedMeals =  this.meals
            }else{
                setTimeout(() => {
                    this.sortedMeals =  this.meals.filter(meal => meal.productCategories.find( ({ id }) => id === data ) );  
                }, 500);
            } 
        },
        toogleModal(){
            this.showModal = !this.showModal
        },
        modalChanges(data){
            this.onLoadSort(JSON.parse(data).name);
        },
        async getAllmeals(){
            let url = this.$store.state.base_url + 'restaurant/'+ this.storeDetails.restaurantId +'/food';
            let res = await this.axios.get(url,{
                params:{
                    page: (this.search) ? 0 : this.page,
                    size: this.size,
                    foodName:this.search
                }
            });

            if(this.search && res.data.content.length){
                this.meals = res.data.content
            }else if(res.data.content.length){
                res.data.content.forEach((item) =>{ 
                    let meal = this.meals.find(meal => meal.foodId == item.foodId )
                    if(item.productCategories.length){
                        item.productCategories.forEach((item) => {                           
                            let categoryItem = this.categories.find(element => element.id == item.id);                            
                            if(!categoryItem) {
                                this.categories.push(item)
                            }                            
                        });
                    }

                    if(!meal){
                        this.meals.push(item);
                    }                   
                });
            }
            if(this.$store.getters.getTimebelt){
                let timebelt = this.$store.getters.getTimebelt.name
                this.sortedMeals =  this.meals.filter(meal => meal.timeBelts.find( ({ name }) => name === timebelt ) );                  
            }
            this.totalPage = res.data.totalPages              
        },
        sortTimebelt(timebelts){
            timebelts.sort(function(a, b) {
                    return a.id - b.id;
                });
            return timebelts;
        },
        sortMeals(timebelt,event){
            const pills = document.querySelectorAll('.pill');
            for (let i = 0; i < pills.length; i++) {
                const element = pills[i];
                 if (element.classList.contains('active')) {
                    element.classList.remove('active')
                }   
            }
            event.target.classList.add('active');
            if(timebelt == 'all'){
                this.sortedMeals =  this.meals
            }else{
                setTimeout(() => {
                    this.sortedMeals =  this.meals.filter(meal => meal.timeBelts.find( ({ name }) => name === timebelt ) );  
                }, 500);
            } 
        },
        onLoadSort(timebelt){
            const pills = document.querySelectorAll('.pill');
            for (let i = 0; i < pills.length; i++) {
                const element = pills[i];
                if (element.classList.contains('active')) {
                    element.classList.remove('active');
                }else if (element.innerText.toLowerCase() == timebelt.toLowerCase() ){
                    element.classList.add('active')
                }
            }
            setTimeout(() => {
                this.sortedMeals =  this.meals.filter(meal => meal.timeBelts.find( ({ name }) => name === timebelt ) );  
            },500);          
        },
        searchMeals(){
            this.getAllmeals()    
        },
        clearSearch(){
            if(!this.search) {
                this.sortedMeals = ""
            }     
        },
        toggleMobileCart(){
           this.showMobileCart = !this.showMobileCart
        },
        restrictionTime(hours,mins){
            let restrictionTime = new Date(`${hours}:${mins} :00`);
            return moment(restrictionTime, "h:mm:ss A").subtract(1, 'hours').format("HH:mm:ss");
        },
        selectTimebelt(){
            let timebelts = this.storeDetails.availableTimeBelts
            timebelts.sort(function(a, b) {
                return a.id - b.id;
            });
            
            let activeTimebelts = timebelts.filter(item => {
                if(moment.tz("Africa/Lagos").hour() < this.convertTimebelt(item.endTime)){
                    return item;
                }
            });

            activeTimebelts.sort((a,b) => {
                return this.convertTimebelt(a.endTime) -  this.convertTimebelt(b.endTime);
            });

            if(activeTimebelts.length){
                this.$store.dispatch('addTimeBelt',JSON.stringify(activeTimebelts[0]));
                this.onLoadSort(activeTimebelts[0].name);
                this.$store.dispatch('addDeliverydate', moment().format());
            }else{
                this.$store.dispatch('addDeliverydate', moment().format());
                this.$store.dispatch('addTimeBelt',JSON.stringify(timebelts[0]));
                this.onLoadSort(timebelts[0].name);
            }
            
        },
        convertTimebelt(time){
            time.replace(/\s+/g);
            let hours = time.match(/\d+/)[0]
            let string = time.replace(/\d+/,'');
            hours = (string.toLowerCase() == 'pm' && hours != 12) ? parseInt(hours) + 12 : hours
            return hours
        },
        toogleAddress(){
            this.showAddress = !this.showAddress
        },
        getAddressData: function (addressData, placeResultData, id) {
            this.newAddress = addressData;
            this.newAddress.address = (addressData.street_number) ?`${addressData.street_number}, ${addressData.route}, ${addressData.locality}, ${addressData.country}`:`${addressData.route} ${addressData.locality}, ${addressData.country}`
            this.inputAddress = this.newAddress.address;
            this.$refs.address.update(this.newAddress.address);
        },
        async checkRestaurant(){
            if(this.newAddress){
                let geoData = {
                    address: this.newAddress.address,
                    latitude:this.newAddress.latitude,
                    longitude: this.newAddress.longitude
                }
                this.$store.dispatch('addDeliveryaddress',JSON.stringify(geoData));
                this.toogleAddress();
                await this.getResturantsDetails();
                await this.getAllmeals();  
                this.toogleModal(); 

            }else{
                this.error = "Invaild delivery address"
            }
        },
        async GetReviews(){
            try{
                let url = this.$store.state.base_url + 'review/public/published/restaurant/' + this.storeDetails.restaurantId;
                let res = await this.axios.get(url);
                this.reviewsData = res.data;
                this.reviewsData.sort((a, b) =>  new Date(b.createdAt) - new Date(a.createdAt));
            }catch(err){
                console.log(err)
            }
        },
        async getMinimumCost(){
            try{
                let url = this.$store.state.base_url + `public/configs/minimum-order-amount`;
                let res = await this.axios.get(url);
                this.minimumOrderCost = res.data;
            } catch(error){
                console.log(error);
            }
        },
        toogleShowContainerPopup(){
            this.showContainerPopup = !this.showContainerPopup
        },
        async loadMore(){
            this.showLoadmorePreloader = true;
            this.page++
            await this.getAllmeals()
            this.showLoadmorePreloader = false;

        }

    },
    computed:{
        getCart(){
           return this.$store.state.cart;
        },
        getCost(){
            return this.global.getCartCost(this.getCart)
        },
        async getWorldTIme(){
            let url = this.$store.state.base_url + 'public/configs/date';
            delete axios.defaults.headers.common['Authorization'];
            let res = await axios.get(url,{});
            this.currentDate =  res.data
        }
    },
    beforeCreate(){
        this.$store.dispatch('emptyTimebelt')
    },
    async mounted(){
        if(this.$store.getters.getAddress.address){
            await this.getWorldTIme
            await this.getResturantsDetails();
            this.$store.dispatch('addResturantName',this.storeDetails.publicURL)
            await this.getAllmeals();  
            this.GetReviews();
            if(this.storeDetails.bookingType === 'INSTANT'){
                this.selectTimebelt()
            }else{
                this.toogleModal();
            }
            this.getMinimumCost();
        }else{
            this.toogleAddress();
        }
         
    },
    unmounted(){
      this.$store.dispatch('emptyTimebelt')
    }
}
</script>

<style>
:root{
	--loader-width: 20px;
	--loader-height: 20px;
	--loader-color-primary: #000000;
	--loader-color-secondary: #eee;
	--line-width: 2px;
	--animation-duration: 2s;
	--loader-initial-scale: 0.1;
}
.mscreen{
        height: 90vh;
    margin-top: 4rem;
}
</style>