<template>
    <div @click="toggleModal()" class="mt-4 overflow-hidden bg-white border rounded-md shadow-md md:cursor-pointer" >
        <div class="flex items-stretch space-x-3">
            <div class="flex-shrink-0 w-28 md:w-52 md:h-52 ">
                    <img :src="data.images[0].image" class="object-cover min-h-full" alt="">
            </div>
            <div class="flex-wrap flex-grow mt-4">
                <div class="relative">
                    <h1 class="pr-5 font-semibold break-words transition duration-200 ease-in">{{ data.name }}<span v-if="data.unitOfMeasure" class="text-sm captialize" >/{{ data.unitOfMeasure.toLowerCase() }}</span></h1>
                    <div>
                        <span v-if="data.foodType ==='COMBO'" >
                            <span  class="px-2 py-1 text-xs text-gray-100 bg-orange-400 rounded-full" >Combo</span>
                        </span>
                        <span v-else-if="data.foodType ==='PORTION'">
                            <span class="px-2 py-1 text-xs text-gray-100 rounded-full bg-amber-400" >Extra/Portion</span>
                        </span>
                    </div>
                </div> 
                <p class="pr-5 mt-3 overflow-hidden text-sm break-words md:flex overflow-ellipsis">
                    {{ data.description }}
                </p>
                <div v-if="!isInstant" class="block mt-4 text-sm md:flex md:space-x-2" >
                    <p>Available in:</p>
                    <p class="flex flex-wrap">
                        <span v-for="(category , index) in sortTimebelt(data.timeBelts).filter(item => item.name != 'Instant')" :key="category">
                            <span class="capitalize" v-if="index + 1  == data.timeBelts.filter(item => item.name != 'Instant').length" >
                                {{ category.name.toLowerCase() }} 
                            </span>
                            <span v-else class="capitalize" >
                                {{ category.name.toLowerCase() }}, &nbsp;
                            </span>
                        </span>
                    </p> 
                </div>
                <div class="flex flex-col-reverse mb-3 space-x-4 md:flex-row md:my-3">
                    <div class="flex space-x-3">
                        <div class="flex mt-4 space-x-2">
                            <button class="px-3 text-black rounded-sm bg-brand md:px-4" @click="minusQty" >
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 12H6" />
                                </svg>
                            </button>
                            <p class="flex items-center px-3 border rounded-sm md:px-4">{{ Quantity}} </p>
                            <button class="px-3 text-black rounded-sm bg-brand md:px-4 " @click="addQty">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                </svg>
                            </button>
                        </div>
                        <div v-if="details.isOpen" class="flex justify-end">
                            <button @click="addCart" class="px-4 py-2 mt-4 text-sm font-semibold text-black rounded-sm shadow-lg bg-brand " >
                                    <span class="hidden text-xs md:block " >Add to cart</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 md:hidden" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                    </svg>
                            </button>
                        </div>
                        <div v-else class="flex justify-end">
                            <button disabled class="px-4 py-2 mt-4 text-sm font-semibold text-black bg-gray-200 rounded-sm shadow-lg " >
                                <span class="hidden text-xs md:block " @click="alert('Restuarant is closed')" >Add to cart</span>
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 md:hidden" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                </svg>
                            </button>
                        </div> 
                    </div>
                    <div class="flex justify-end flex-grow mt-2 ">
                        <div class="flex items-end" >
                            <div class="flex px-4 py-2 space-x-2 text-black rounded-l-full bg-brand w-42 ">
                                <p class="text-base font-semibold">&#8358;{{ formatNumber(data.price) }}</p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Modal v-if="error" :errorMsg="error" :show="true"> 
        <h1 class="text-xl font-semibold">Do you want to proceed?</h1>
        <p class="mt-4 text-sm" > You have tried to add items from different time belts or different restaurants. Changing any of these will clear your current cart and a new one will be created.</p>
        <div class="flex justify-end space-x-4">
            <button @click="noAction" class="px-3 py-2 text-sm bg-gray-200 rounded-sm" >No</button>
            <button @click="clearCart" class="px-3 py-2 text-sm rounded-sm bg-brand" >Yes</button>
        </div>
    </Modal>
    <div v-if="showCartToast" class="fixed lg:top-24 lg:right-8 top-24 right-4">
        <div v-if="cartMessage" class="flex gap-3 px-4 py-2 text-sm text-gray-100 bg-green-400 rounded-full animate__animated animate__bounceIn">
            <div>{{ cartMessage }}</div>
            <button @click="toogleCartToast">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>                  
            </button>
        </div>
    </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import moment from "moment";
import 'animate.css';

export default {
    name:"FoodCard",
    props:['data','isInstant','id','details'],
    components:{
        Modal
    },
    data(){
        return{
            Quantity: 1,
            error : false,
            cartMessage:"",
            showCartToast:false
        }
    },
    methods:{
        addQty(){
            this.Quantity++
        },
        minusQty(){
            if (this.Quantity > 1) {
                this.Quantity--
            }
        },
        toggleModal(){
            this.showModal = !this.showModal
        },
        toogleCartToast(){
            this.showCartToast = !this.showCartToast
        },
        showCartToastMessage(message){
            if(this.cartMessage){
                this.showCartToast = false
            }
            this.cartMessage = message;
            this.toogleCartToast()
            setTimeout(()=>{
                this.cartMessage = "";
                this.showCartToast = false;
            },1000)
        },
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        noAction(){
            this.error = false
        },
        sortTimebelt(timebelts){
            timebelts.sort(function(a, b) {
                    return a.id - b.id;
                });
            return timebelts;
        },
        clearCart(){
            this.$store.dispatch('emptyCart')
            this.$store.dispatch('emptyResurantName')
            this.$router.go();
        },
        addCart(){
            let timeBelt =  this.$store.getters.getTimebelt;
            let deliveryDate = moment(new Date(this.$store.getters.getDate)).format();
            let foodName = `${this.data.name}${(this.data.unitOfMeasure) ? `/${this.data.unitOfMeasure.toLowerCase()}` : ""}`
            let item = {
                foodId : this.data.foodId,
                foodName: foodName,
                quantity : this.Quantity,
                amount: this.data.price,
                foodImage: this.data.images[0].image,
                timeBeltId: timeBelt.id,
                expectedDeliveryDate:deliveryDate, 
                restaurantId: this.id,
                deliveryAddress: this.$store.getters.getAddress.address,
                foodType: this.data.foodType
            };
            const result = this.data.timeBelts.find( ({ name }) => name === timeBelt.name );
            if (typeof result !== 'undefined' || !timeBelt) {
                let cart = this.$store.getters.getCart;
                if(!cart.cartItems.length){
                    cart.cartItems.push(item)
                    this.showCartToastMessage(`Added ${foodName} to cart`)
                    this.$store.dispatch('addCart',cart)
                }else{
                    let result = cart.cartItems.find(({ foodId }) => foodId === item.foodId );
                    if(result){
                        cart.cartItems.map((elem) => { 
                            if (elem.foodId === item.foodId) {
                                elem.quantity = elem.quantity + item.quantity
                            }  
                        });
                        this.showCartToastMessage(`Added ${foodName} to cart`)
                        this.$store.dispatch('addCart',cart);
                    }else{       
                        cart.cartItems.push(item);
                        this.showCartToastMessage(`Added ${foodName} to cart`)
                        this.$store.dispatch('addCart',cart);
                    }
                }
                //Update count array 
                let count = this.$store.getters.getCart.cartItems.length + this.$store.getters.getCart.containers.length
                this.$store.dispatch('addCountcart',count);
            }else{
                this.error = true
            }
            this.Quantity = 1;
        },
    },
}
</script>

<style>

</style>