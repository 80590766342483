<template>
  <Modal
    v-if="showContainerPopup"
    :show="true"
    :width="arrangeContainer ? 'lg:w-600' : 'lg:w-96'"
  >
    <div v-if="!arrangeContainer">
      <div class="flex justify-between">
        <h1 class="text-2xl font-semibold">Select Container</h1>
        <span class="cursor-pointer" @click="closeContainerModal">&times;</span>
      </div>
      <div class="my-2 text-sm">
        We found at least one portion meal in your cart. Please select a container to pack your meals.
      </div>
      <hr class="my-4" />
      <div
        class="grid grid-cols-2 gap-4 mb-6 lg:grid-cols-3"
        v-if="!containers.length"
      >
        <div
          v-for="container in storeContainers"
          :key="container"
          @click="createContainer(container.id)"
        >
          <div
            class="bg-white rounded-md shadow-md cursor-pointer"
            style="height: 147px"
          >
            <div
              class="flex justify-center h-24 overflow-hidden rounded-md bg-brand"
            >
              <img
                :src="container.images[container.images.length - 1]"
                class="object-fill w-full h-24"
                alt=""
              />
            </div>
            <div class="p-3">
              <div class="flex justify-between text-xs">
                <div>
                  {{ container.name }}
                </div>
                <div class="text-brand">&#8358;{{ container.price }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end flex-grow mt-4 space-x-2">
        <button
          v-if="this.containers.length && vaildateCart"
          @click="proceedBuildContainer"
          type="submit"
          class="px-3 py-2 text-sm font-semibold text-black rounded bg-brand"
        >
          Proceed
        </button>
        <button
          @click="closeContainerModal"
          type="submit"
          class="px-3 py-2 text-sm font-semibold text-gray-100 bg-red-500 rounded"
        >
          Cancel
        </button>
      </div>
    </div>
    <div v-else>
      <h1 class="mb-4 text-2xl font-semibold">Add Meal(s) to Selected Containers</h1>
      <div v-if="error" class="my-2 text-sm text-red-500" style="color: red">
        {{ error }}
      </div>
      <div
        id="scrollbar1"
        :class="{ 'h-400': containers.length || portionMeals.length > 3 }"
        class="pr-3 lg:overflow-auto scrollbar scrollbar-thumb-rounded-full scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent scrollbar-track-rounded-full"
      >
        <div v-if="containers.length" class="mb-4">
          <div
            v-for="(container, index) in containers"
            :key="container"
            class="mb-6"
          >
            <div
              class="p-4 mt-1 ml-1"
              style="box-shadow: 0 0 5px 1px thumb-gray-300"
              :style="{
                boxShadow: '1px 1px 5px 1px rgba(169, 169, 169, 0.5)',
              }"
            >
              <div class="flex justify-between text-lg">
                <span v-if="anyAvailablePortion">
                  Click the + sign against a meal to add to container
                </span>
                <span v-else> All portion meals added </span>

                <span
                  class="text-3xl cursor-pointer"
                  @click="deleteContainer(index)"
                  >&times;</span
                >
              </div>
              <hr class="my-4" />
              <div
                v-if="anyAvailablePortion"
                class="grid grid-cols-2 gap-3 mb-6 lg:grid-cols-3"
              >
                <div
                  v-for="portion in portionMeals.filter(
                    (item) => item.quantity != 0
                  )"
                  :key="portion"
                  class="my-2 mb-4 text-sm"
                >
                  <div
                    v-if="portion.quantity"
                    class="relative grid grid-cols-5 gap-0 overflow-hidden rounded-md shadow"
                  >
                    <div class="col-span-2">
                      <img
                        :src="portion.foodImage"
                        class="object-cover h-full w-14"
                      />
                    </div>
                    <div class="col-span-3 py-3">
                      <h1 class="text-xs font-semibold">
                        {{ portion.foodName }}
                      </h1>
                      <h1 class="my-1 text-xs">
                        Portion: {{ portion.quantity }}
                      </h1>
                      <div class="text-xs">&#8358;{{ portion.amount }}</div>
                    </div>
                    <button
                      @click="addToContainer(portion.foodId, index)"
                      title="Add meal to container"
                      class="absolute right-0 flex items-center justify-center w-6 h-6 text-xl rounded-l-full bottom-4 bg-brand"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <div v-else class="p-4 mb-4">
                <!-- <p class="text-sm text-center text-gray-500">
                  No available meals
                </p> -->
              </div>
              <div class="flex justify-between">
                <div class="flex gap-2">
                  <h1 class="text-xl font-semibold text-brand">
                    {{ container.container.name }}
                  </h1>
                  <div>
                    <div class="p-1 text-xs font-semibold rounded-md bg-brand">
                      &#8358;{{ container.container.price }}
                    </div>
                  </div>
                </div>
                <div>
                  <!-- <button @click="deleteContainer(index)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-5 h-5 text-red-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </button> -->
                </div>
              </div>
              <div
                v-if="container.cartItems.length"
                class="flex flex-col gap-4 p-4 my-3 bg-gray-100 rounded-md"
              >
                <div
                  v-for="meal in container.cartItems"
                  :key="meal"
                  class="text-sm"
                >
                  <div class="grid grid-cols-2 lg:grid-cols-3">
                    <div>{{ meal.foodName }}</div>
                    <div class="text-center lg:text-centerm text-brand">
                      &#8358;{{ meal.amount }}/portion
                    </div>
                    <div
                      class="items-center justify-center hidden gap-6 lg:flex"
                    >
                      <div class="flex items-center gap-2">
                        <button
                          @click="substractMeal(index, meal.foodId)"
                          class="flex items-center justify-center w-6 h-6 bg-gray-300 rounded-md"
                          title="remove meal"
                        >
                          -
                        </button>
                        <div
                          class="flex items-center justify-center w-6 h-6 text-xs rounded-md"
                        >
                          {{ meal.quantity }}
                        </div>
                        <button
                          title="add more"
                          @click="addMeal(index, meal.foodId)"
                          class="flex items-center justify-center w-6 h-6 rounded-md bg-brand"
                        >
                          +
                        </button>
                      </div>
                      <div class="flex items-center">
                        <button @click="removeMeal(index, meal.foodId)" title="remove meal from container">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-3 h-3"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex items-center justify-start gap-3 mt-4 lg:hidden"
                  >
                    <div class="flex items-center gap-2">
                      <button
                        @click="substractMeal(index, meal.foodId)"
                        class="flex items-center justify-center w-6 h-6 bg-gray-300 rounded-md"
                      >
                        -
                      </button>
                      <div
                        class="flex items-center justify-center w-6 h-6 text-xs rounded-md"
                      >
                        {{ meal.quantity }}
                      </div>
                      <button
                        @click="addMeal(index, meal.foodId)"
                        class="flex items-center justify-center w-6 h-6 rounded-md bg-brand"
                      >
                        +
                      </button>
                    </div>
                    <div class="flex items-center">
                      <button @click="removeMeal(index, meal.foodId)">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="w-5 h-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="p-4 my-3 bg-gray-100 rounded-md">
                <div
                  class="text-lg font-bold text-center"
                  style="color: #d3d3d3"
                >
                  No meal added
                </div>
              </div>

              <div
                class="flex justify-between p-4 bg-brand"
                v-if="totalAmount(container.cartItems)"
              >
                <span class="">x{{ container.cartItems.length }} item(s)</span>
                <span
                  >Total : N{{
                    formatNumber(totalAmount(container.cartItems))
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div
            v-if="portionMeals.length"
            class="grid grid-cols-2 gap-3 mb-6 lg:grid-cols-3"
          >
            <div
              v-for="portion in portionMeals"
              :key="portion"
              class="my-2 mb-4 text-sm"
            >
              <div
                v-if="portion.quantity"
                class="relative grid grid-cols-5 gap-0 overflow-hidden rounded-md shadow"
              >
                <div class="col-span-2">
                  <img
                    :src="portion.foodImage"
                    class="object-cover h-full w-14"
                  />
                </div>
                <div class="col-span-3 py-3">
                  <h1 class="text-xs font-semibold">{{ portion.foodName }}</h1>
                  <h1 class="my-1 text-xs">Portion: {{ portion.quantity }}</h1>
                  <div class="text-xs">&#8358;{{ portion.amount }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h1 class="mb-4 text-xl font-semibold">Add Container</h1>
        <div class="grid grid-cols-2 gap-4 mb-6 lg:grid-cols-3">
          <div
            v-for="container in storeContainers"
            :key="container"
            @click="createContainer(container.id)"
          >
            <div class="bg-white rounded-md shadow-md cursor-pointer">
              <div
                class="flex justify-center h-24 overflow-hidden rounded-md bg-brand"
              >
                <img
                  title="click to add this container"
                  :src="container.images[container.images.length - 1]"
                  class="object-fill w-full h-24"
                  alt=""
                />
              </div>
              <div class="p-3">
                <div class="flex justify-between text-xs">
                  <div>
                    {{ container.name }}
                  </div>
                  <div>&#8358;{{ container.price }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end flex-grow mt-4 space-x-2">
        <div>
          <button
            v-if="!anyAvailablePortion"
            @click="validateThenCloseCart"
            type="submit"
            class="px-3 py-2 text-sm font-semibold text-black rounded bg-brand"
          >
            Update cart
          </button>
          <button
            v-if="anyAvailablePortion"
            @click="scrollTo"
            class="px-3 py-2 text-sm font-semibold text-black rounded bg-brand"
          >
            Add Container
          </button>
        </div>
        <button
          @click="restoreLastCartChanges"
          type="submit"
          class="px-3 py-2 text-sm font-semibold text-gray-100 bg-red-500 rounded"
        >
          Cancel
        </button>
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/Modal.vue";
export default {
  name: "Container",
  props: ["storeContainers", "showContainerPopup"],
  emits: ["closeContainerModal"],
  components: {
    Modal,
  },
  data() {
    return {
      arrangeContainer: false,
      containers: [],
      portionMeals: [],
      error: "",
      emptyCart: "",
    };
  },
  computed: {
    anyAvailablePortion() {
      let totalQuantity = 0;
      this.portionMeals.forEach((item) => {
        totalQuantity = totalQuantity + item.quantity;
      });
      return totalQuantity ? true : false;
    },
  },
  methods: {
    proceedBuildContainer() {
      this.arrangeContainer = true;
      this.portionMeals = this.$store.getters.getCart.cartItems.filter(
        (item) => item.foodType == "PORTION" && item.quantity != 0
      );
      this.containers = this.$store.getters.getCart.containers;
      this.$store.dispatch("saveRevertedCart", this.$store.getters.getCart);
    },
    restoreLastCartChanges() {
      this.$store.dispatch("addCart", this.$store.state.revertedCart);
      this.arrangeContainer = false;
      this.$emit("closeContainerModal");
    },
    closeContainerModal() {
      this.arrangeContainer = false;
      this.$emit("closeContainerModal");
    },
    totalAmount(arr) {
      return arr.reduce((acc, obj) => acc + obj.amount, 0);
    },

    validateThenCloseCart() {
      let emptyCart = [];
      this.containers.forEach((item) => {
        if (!item.cartItems.length) {
          emptyCart.push(false);
        }
      });
      if (this.containers.length && emptyCart.length) {
        this.error = `A container has no portion, either you delete it or add portions to the container`;
      } else {
        this.arrangeContainer = false;
        this.error = "";
        this.$emit("closeContainerModal");
      }
    },
    vaildateCart() {
      let emptyCart = [];
      this.containers.forEach((item) => {
        if (!item.cartItems.length) {
          emptyCart.push(false);
        }
      });
      if (this.containers.length && emptyCart.length) {
        return false;
      } else {
        return true;
      }
    },
    scrollTo() {
      const modal = document.getElementById("scrollbar1");
      let emptyCart = [];
      this.containers.forEach((item) => {
        if (!item.cartItems.length) {
          emptyCart.push(false);
        }
      });
      if (this.containers.length && emptyCart.length) {
        modal.scrollTop = 0;
        return (this.error = `A container has no portion, either you delete it or add portions to the container`);
      }
      if (modal) {
        modal.scrollTop = modal.scrollHeight;
      }
    },
    createContainer(id) {
      if (
        this.containers &&
        this.containers.some((x) => x.cartItems.length === 0)
      ) {
        this.error =
          "An empty container exist, please proceed to add meals or remove container.";
        console.log(this.containers, "Empty cart container found");
        return;
      }
      this.error = "";
      let container = this.storeContainers.find((item) => item.id == id);
      let data = {
        container: container,
        cartItems: [],
        quantity: 1,
      };

      this.containers.push(data);
      this.updateAllItems();
      this.proceedBuildContainer();
    },
    updateContainer() {
      let cart = this.$store.getters.getCart;
      cart.containers = this.containers;
      return cart.containers;
    },
    updateAllItems() {
      let cart = {};
      cart.containers = this.updateContainer();
      cart.cartItems = this.updateCartItem();
      this.$store.dispatch("addCart", cart);
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    deleteContainer(position) {
      let container = this.containers.find((item, index) => index == position);
      if (container.cartItems.length) {
        container.cartItems.forEach((element) => {
          let meal = this.portionMeals.find(
            (item) => item.foodId == element.foodId
          );
          if (meal) {
            this.portionMeals.map((item) => {
              if (item.foodId == element.foodId) {
                item.quantity = item.quantity + element.quantity;
              }
            });
          } else {
            //add to portion
            this.portionMeals.push(element);
          }
          this.updateAllItems();
        });
      }
      this.containers = this.containers.filter(
        (item, index) => index != position
      );
      this.updateAllItems();
    },
    updateCartItem() {
      //Update cartItems with new portion
      let cart = this.$store.getters.getCart;
      this.error = "";
      this.portionMeals.forEach((element) => {
        if (element.quantity > 0) {
          let meal = cart.cartItems.find(
            (item) => item.foodId == element.foodId
          );
          if (meal) {
            cart.cartItems.map((item) => {
              if (item.foodId == element.foodId && element.quantity) {
                item.quantity = element.quantity;
              }
            });
          } else {
            //add to portion
            cart.cartItems.push(element);
          }
        }
      });

      cart.cartItems = cart.cartItems.filter((item) => item.quantity > 0);

      return cart.cartItems;
    },
    addToContainer(meal, indexOfContainer) {
      this.error = "";
      let food = this.portionMeals.find((item) => item.foodId == meal);
      let data = {
        foodId: food.foodId,
        foodName: food.foodName,
        quantity: 1,
        amount: food.amount,
        foodImage: food.foodImage,
        timeBeltId: food.timeBeltId,
        expectedDeliveryDate: food.expectedDeliveryDate,
        restaurantId: food.restaurantId,
        deliveryAddress: food.deliveryAddress,
        foodType: food.foodType,
      };

      this.portionMeals.map((item) => {
        if (item.foodId == meal) {
          item.quantity = item.quantity - 1;
        }
      });

      this.containers.map((item, index) => {
        let meal = item.cartItems.find((item) => item.foodId == food.foodId);
        if (index == indexOfContainer && !meal) {
          item.cartItems.push(data);
        } else if (index == indexOfContainer && meal) {
          item.cartItems.map((item) => {
            if (item.foodId == meal.foodId) {
              item.quantity = item.quantity + 1;
            }
          });
        }
      });
      let updatedcartMeal = this.portionMeals.find(
        (item) => item.foodId == meal
      );
      this.updateAllItems();
      if (updatedcartMeal.quantity == 0) {
        this.removePortionFromCart(meal);
      }
    },
    addMeal(indexOfContainer, foodId) {
      let cartMeal = this.portionMeals.find((item) => item.foodId == foodId);
      this.containers.map((item, index) => {
        if (index == indexOfContainer) {
          item.cartItems.map((item) => {
            if (item.foodId == foodId && cartMeal.quantity > 0) {
              item.quantity = item.quantity + 1;
            }
          });
        }
      });

      this.portionMeals.map((item) => {
        if (item.foodId == foodId && item.quantity > 0) {
          item.quantity = item.quantity - 1;
        }
      });
      let updatedcartMeal = this.portionMeals.find(
        (item) => item.foodId == foodId
      );
      this.updateAllItems();
      if (updatedcartMeal.quantity == 0) {
        this.removePortionFromCart(foodId);
      }
    },
    substractMeal(indexOfContainer, foodId) {
      this.containers.map((item, index) => {
        if (index == indexOfContainer) {
          let meal = item.cartItems.find((item) => item.foodId == foodId);
          if (meal && meal.quantity - 1) {
            meal.quantity = meal.quantity - 1;
          } else {
            item.cartItems = item.cartItems.filter(
              (item) => item.foodId != foodId
            );
          }
        }
      });

      this.portionMeals.map((item) => {
        if (item.foodId == foodId) {
          item.quantity = item.quantity + 1;
        }
      });

      this.updateAllItems();
    },
    removeMeal(indexOfContainer, foodId) {
      this.containers.map((item, index) => {
        if (index == indexOfContainer) {
          let meal = item.cartItems.find((item) => item.foodId == foodId);
          this.portionMeals.map((item) => {
            if (item.foodId == meal.foodId) {
              item.quantity = item.quantity + meal.quantity;
            }
          });
          item.cartItems = item.cartItems.filter(
            (item) => item.foodId != foodId
          );
        }
      });
      this.updateAllItems();
    },
    removePortionFromCart(id) {
      let cart = this.$store.getters.getCart;
      cart.cartItems = cart.cartItems.filter((item) => item.foodId != id);
      this.$store.dispatch("addCart", cart);
    },
  },
};
</script>