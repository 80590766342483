<template>
  <div class="flex flex-col gap-3">
    <div>
      <h1 class="text-sm font-semibold text-gray-500">Order From:</h1>
      <div class="flex justify-between text-lg font-semibold">
        <p>{{ restaurant.name }}</p>
      </div>
      <div  v-if="restaurant.bookingType === 'SCHEDULED'"  class="flex">
        <div v-if="instant == 'instant'">
          <h1 class="mt-2 text-sm font-semibold text-gray-500">
            Estimated Time:
          </h1>
          <div class="flex justify-between text-lg font-semibold">
            <p v-if="minTime == maxTime" class="text-sm">{{ maxTime }} mins</p>
            <p v-else class="text-sm">{{ minTime }} - {{ maxTime }} mins</p>
          </div>
        </div>
        <div v-else-if="instant == 'scheduled'">
          <h1 class="mt-2 text-sm font-semibold text-gray-500">Timebelt</h1>
          <p v-if="getSavedTimebelt" class="text-lg font-semibold">
            {{ getSavedTimebelt.name }} ( {{ getSavedTimebelt.startTime }} -
            {{ getSavedTimebelt.endTime }} )
            <button
              @click="changeTimebelt"
              class="hidden text-xs font-semibold text-brand"
            >
              Change
            </button>
          </p>
          <p v-else class="text-lg font-semibold">-</p>
        </div>
      </div>
    </div>
    <div>
      <div v-if="getCart.cartItems.length || getCart.containers.length">
        <div
          :class="{
            'h-72': getCart.cartItems.length + getCart.containers.length > 3,
          }"
          class="pr-4 overflow-y-auto scrollbar h-72 scrollbar-thumb-rounded-full scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-200 scrollbar-track-rounded-full"
        >
          <div class="grid grid-rows-1">
            <ContainerCart
              v-for="(item, index) in getCart.containers"
              :key="item"
              :container="item"
              name=""
              :position="index"
            />
            <FoodCart
              v-for="item in getCart.cartItems"
              :key="item"
              :data="item"
            />
          </div>
          <div class="grid grid-cols-2 mt-2">
            <div class="">
              <div v-if="!isInstant" class="text-sm">Delivery date</div>
              <div v-if="!isInstant" class="font-semibold">
                {{ getDeliveryDate }}
              </div>
            </div>
            <div class="">
              <div class="text-sm text-right">Total</div>
              <div class="font-semibold text-right">
                &#8358;{{ formatNumber(Total) }}
              </div>
            </div>
          </div>
          <div
            v-if="restaurant.bookingType == 'SCHEDULED'"
            class="text-xs font-semibold text-gray-500"
          >
            Expect your meal on or before {{ getSavedTimebelt.endTime }}
          </div>
        </div>
      </div>
      <div v-else class="flex items-center justify-center my-24 text-gray-400">
        <div class="block">
          <div class="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-14 w-14"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
              />
            </svg>
          </div>
          <p class="mx-3 mt-3 text-sm text-center text-black">
            Your cart is empty.
          </p>
        </div>
      </div>
    </div>
    <div>
      <div class="w-full">
        <button
          @click="Checkout()"
          :class="{
            'bg-gray-300':
              !getCart.cartItems.length || !getCart.containers.length,
            'bg-brand  shadow-lg ':
              getCart.cartItems.length || getCart.containers.length,
          }"
          class="flex justify-center w-full py-4 my-4 font-semibold text-black rounded"
        >
          <Preloader v-if="showPreloader" />
          <span v-else>Proceed to Checkout</span>
        </button>
      </div>
    </div>
  </div>
  <div v-if="showModal">
    <Modal :show="true">
      <h1 class="text-xl font-semibold">Do you want to proceed?</h1>
      <p class="mt-4 text-sm">
        You have tried to add items from different time belts or different
        restaurants. Changing any of these will clear your current cart and a
        new one will be created.
      </p>
      <div class="flex justify-end space-x-4">
        <button
          @click="toggleModal"
          class="px-3 py-2 text-sm bg-gray-200 rounded-sm"
        >
          No
        </button>
        <button
          @click="clearTimebelt()"
          class="px-3 py-2 text-sm rounded-sm bg-brand"
        >
          Yes
        </button>
      </div>
    </Modal>
  </div>
  <div class="z-999" v-if="redirect">
    <Modal :show="true">
      <h1 class="text-xl font-semibold">Login your account</h1>
      <p class="mt-4 text-sm">
        To complete your purchase, you are required to Log in/Sign up
      </p>
      <div class="flex justify-end mt-2 space-x-4">
        <button
          @click="closeModal"
          class="px-3 py-2 text-sm font-semibold bg-gray-200 rounded-sm"
        >
          Go Back
        </button>
        <button
          @click="redirectLogin"
          class="px-3 py-2 text-sm font-semibold rounded-sm bg-brand"
        >
          Continue
        </button>
      </div>
    </Modal>
  </div>
  <div class="z-999" v-if="MinimumModal">
    <Modal :show="true">
      <h1 class="text-xl font-semibold">Add more item to cart</h1>
      <p class="mt-4 text-sm">
        Items in your cart are below minimum order value &#8358;{{
          this.global.formatNumber(minimumOrderCost)
        }}
      </p>
      <div class="flex justify-end mt-6 space-x-4">
        <button
          @click="toggleMinimumModal"
          class="px-3 py-2 text-sm font-semibold bg-gray-200 rounded-sm"
        >
          Go Back
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import FoodCart from "@/components/FoodCart.vue";
import ContainerCart from "@/components/ContainerCart.vue";
import Modal from "@/components/Modal.vue";
import moment from "moment";
import Preloader from "@/components/Preloader.vue";
export default {
  name: ["FoodCart"],
  props: [
    "restaurant",
    "isInstant",
    "minTime",
    "maxTime",
    "getCart",
    "Total",
    "minimumOrderCost",
    "allowBookingPortion",
  ],
  emits: ["changeTimebelt", "showContainerPopup"],
  components: {
    FoodCart,
    Modal,
    Preloader,
    ContainerCart,
  },
  data() {
    return {
      Quantity: 1,
      cart: [],
      instant: "scheduled",
      getTimebelt: "",
      showModal: false,
      showPreloader: false,
      redirect: false,
      MinimumModal: false,
    };
  },
  methods: {
    async Checkout() {
      if (
        this.getCart.cartItems.length > 0 ||
        this.getCart.containers.length > 0
      ) {
        let portionMeals = this.getCart.cartItems.filter(
          (item) => item.foodType == "PORTION"
        );
        if (this.Total < this.minimumOrderCost) {
          this.toggleMinimumModal();
        } else if (portionMeals.length > 0 && !this.allowBookingPortion) {
          this.$emit("showContainerPopup");
        } else if (
          (this.getCart.cartItems.length > 0 ||
            this.getCart.containers.length > 0) &&
          this.$store.getters.getUser
        ) {
          //CREATE AN ORDER
          this.showPreloader = true;
          try {
            let res = await this.global.bookOrder();
            this.$store.dispatch("addOrder", JSON.stringify(res.data));
            this.clearCart();
            this.$router.push({ name: "Checkout" });
          } catch (error) {
            console.log(error);
            this.showPreloader = false;
          }
        } else if (!this.$store.getters.getUser) {
          this.redirect = true;
        }
      }
    },
    clearCart() {
      this.$store.dispatch("clearCoutCart");
      this.$store.dispatch("emptyCart");
      this.$store.dispatch("emptyResurantName");
      let count =
        this.$store.getters.getCart.cartItems.length +
        this.$store.getters.getCart.containers.length;
      this.$store.dispatch("addCountcart", count);
    },
    toggleMinimumModal() {
      this.MinimumModal = !this.MinimumModal;
    },
    redirectLogin() {
      this.$router.push({
        name: "CustomerSignIn",
        query: { from: "checkout" },
      });
    },
    closeModal() {
      this.redirect = false;
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    clearTimebelt() {
      //   this.$store.dispatch("clearCoutCart");
      this.$store.dispatch("emptyCart");
      this.$store.dispatch("emptyTimebelt");
      this.$router.go();
    },
    changeTimebelt() {
      this.$emit("changeTimebelt");
    },
    toggleMinimumModal() {
      this.MinimumModal = !this.MinimumModal;
    },
  },
  computed: {
    getSavedTimebelt() {
      return this.$store.getters.getTimebelt
        ? this.$store.getters.getTimebelt
        : {};
    },
    getDeliveryDate() {
      return moment(new Date(this.$store.getters.getDate)).format(
        "MMMM Do YYYY"
      );
    },
  },
  created() {
    this.getTimebelt = this.$store.getters.getTimebelt;
  },
};
</script>

<style>
</style>