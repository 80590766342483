<template>
  <div v-show="!deleteItem" class="py-2 text-sm border-b-2 border-white">
    <div class="grid grid-cols-3">
      <div class="">
        <img
          :src="
            container.container.images[container.container.images.length - 1]
          "
          class="object-cover w-20 h-20 rounded md:h-24 md:w-24"
          alt=""
        />
      </div>
      <div class="col-span-2">
        <div>
          <div class="grid w-full grid-cols-2">
            <p class="text-sm font-semibold md:text-base">
              {{ container.container.name }}
            </p>
            <div class="flex-grow">
              <div
                v-if="name != 'orderSummary'"
                class="flex items-center justify-end"
              >
                <!-- <button
                  @click="deleteContainer()"
                  class="text-red-600"
                  title="Unpack container"
                >
                  <img
                    src="../assets/svg/food-container.svg"
                    style="width: 35px; height: 35px; object-fit: 'contain'"
                    alt=""
                  />
                </button> -->
                <button
                  @click="toggleModal()"
                  class="mt-2 text-red-600"
                  title="Delete entire pack"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="mb-3 text-xs text-ellipsis">
            Items:
            <span v-for="(item, index) in container.cartItems" :key="item"
              >{{ item.foodName }}({{ item.quantity }})<span
                v-if="index + 1 != container.cartItems.length"
                >,</span
              ></span
            >
          </div>
          <div class="grid grid-cols-2 mt-2">
            <div v-if="name != 'orderSummary'" class="flex space-x-2">
              <button class="px-3 text-black bg-brand" @click="minusQty">
                -
              </button>
              <p class="flex items-center px-3 py-1 border">{{ Quantity }}</p>
              <button class="px-3 text-black bg-brand" @click="addQty">
                +
              </button>
            </div>
            <div v-else>Quantity: {{ Quantity }}</div>
            <div class="w-full">
              <div
                v-if="calculateContainerPrice"
                :class="{
                  'justify-start': name == 'orderSummary',
                  'justify-end': name != 'orderSummary',
                }"
                class="flex flex-grow"
              >
                <p
                  class="flex items-center text-sm font-semibold text-right md:text-base"
                >
                  &#8358;
                  {{ this.global.formatNumber(calculateContainerPrice) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal">
      <Modal :show="true">
        <h1 class="text-xl font-semibold">Do you want to proceed?</h1>
        <p class="mt-4 text-sm">
          You are about to delete the entire selection in this pack
        </p>
        <div class="flex justify-end space-x-4">
          <button
            @click="toggleModal"
            class="px-3 py-2 text-sm bg-gray-200 rounded-sm"
          >
            No
          </button>
          <button
            @click="deleteContainerAndSelection()"
            class="px-3 py-2 text-sm rounded-sm bg-brand"
          >
            Yes
          </button>
        </div>
      </Modal>
    </div>
  </div>
</template>
<script>
import Modal from "@/components/Modal.vue";
export default {
  name: "ContainerCart",
  props: ["container", "position", "name"],
  components: {
    Modal,
  },
  data() {
    return {
      Quantity: this.container.quantity,
      QuantityPrice: 0,
      deleteItem: false,
      amount: 0,
      show: false,
      showModal: false,
    };
  },
  computed: {
    calculateContainerPrice() {
      let cost = 0;
      this.container.cartItems.forEach((item) => {
        cost = cost + item.quantity * item.amount;
      });
      this.QuantityPrice = cost + this.container.container.price;

      return this.container.quantity * (cost + this.container.container.price);
    },
  },
  methods: {
    addQty() {
      this.Quantity++;
      this.updateContainer();
    },
    minusQty() {
      if (this.Quantity > 1) {
        this.Quantity--;
        this.updateContainer();
      }
    },
    updateContainer() {
      let cart = this.$store.getters.getCart;
      cart.containers.map((item, index) => {
        if (index == this.position) {
          item.quantity = this.Quantity;
        }
      });
      this.$store.dispatch("addCart", cart);
    },
    deleteContainer() {
      let cart = this.$store.getters.getCart;
      let container = cart.containers.find(
        (item, index) => index == this.position
      );
      if (container.cartItems.length) {
        container.cartItems.forEach((element) => {
          let meal = cart.cartItems.find(
            (item) => item.foodId == element.foodId
          );
          if (meal) {
            cart.cartItems.map((item) => {
              if (item.foodId == element.foodId) {
                item.quantity = item.quantity + element.quantity;
              }
            });
          } else {
            //add to portion
            cart.cartItems.push(element);
          }
        });
      }

      cart.containers = cart.containers.filter(
        (item, index) => index != this.position
      );
      this.$store.dispatch("addCart", cart);
      this.deleteItem = true;
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    showDeleteModal() {},
    deleteContainerAndSelection() {
      let cart = this.$store.getters.getCart;
      let container = cart.containers.find(
        (item, index) => index == this.position
      );
      if (container.cartItems.length) {
        container.cartItems.forEach((element) => {
          cart.cartItems = cart.cartItems.filter(
            (item) => item.foodId != element.foodId
          );
        });
      }
      cart.containers = cart.containers.filter(
        (item, index) => index != this.position
      );
      this.$store.dispatch("addCart", cart);
      this.deleteItem = true;
    },
  },
  mounted() {
    console.log(this.container, "container.container");
  },
};
</script>