<template>
  <div ref="overlay" class="fixed right-0 w-full h-screen bg-overlay" @click.self="closeNav">
    <div ref="cart" class="flex float-right w-full h-screen transition duration-500 ease-in-out transform translate-x-full md:w-96">
        <div class="flex items-center h-screen" >
            <div @click="closeNav" class="relative p-3 text-gray-100 bg-black rounded-l" >
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
            </div> 
        </div>
        <div class="relative flex-grow pt-3 bg-white">
            <div class="mx-3">
                <div>
                    <h1 class="text-sm font-semibold text-gray-500" >Order From:</h1>
                    <div class="flex justify-between text-lg font-semibold">
                        <p>{{ restaurant.name }}</p>
                    </div>
                </div>
                <div  v-if="restaurant.bookingType === 'SCHEDULED'" class="flex">
                    <div v-if="instant == 'instant'">
                        <h1 class="mt-4 text-sm font-semibold text-gray-500" >Estimated Time:</h1>
                        <div class="flex justify-between text-lg font-semibold">
                            <p v-if="minTime == maxTime" class="text-sm" >{{ maxTime  }} mins</p> 
                            <p v-else class="text-sm" >{{ minTime }} - {{ maxTime }} mins</p> 
                        </div>
                    </div>
                    <div v-else-if="instant == 'scheduled'">
                        <h1 class="mt-4 text-sm font-semibold text-gray-500" >Time Belt</h1>
                        <p v-if="getSavedTimebelt" class="text-lg font-semibold" >{{ getSavedTimebelt.name }} ( {{ getSavedTimebelt.startTime }} - {{ getSavedTimebelt.endTime }} ) <button @click="changeTimebelt" class="hidden text-xs font-semibold text-brand">Change</button></p>
                    </div> 
                </div> 
                <div v-if="cartLenght">
                    <!-- {{ getCart }} -->
                    <div class="py-4 overflow-y-auto h-75vh">
                        <div class="grid grid-rows-1">   
                            <ContainerCart v-for="item,index in getCart.containers" :key="item" :container="item" name="" :position="index" />
                            <FoodCart v-for="item in getCart.cartItems" :key="item" name="mobileCart" :data="item"/>
                        </div>
                        <div class="grid grid-cols-2" >
                            <div class="grid grid-rows-2">
                                <div v-if="!isInstant" class="text-sm md:text-base">Delivery date</div>
                                <div v-if="!isInstant" class="text-sm font-semibold md:text-base">{{ getDeliveryDate }}</div>
                            </div>
                            <div class="grid grid-rows-2">
                                <div class="text-right">Total</div>
                                <div class="text-sm font-semibold text-right md:text-base">&#8358;{{ formatNumber(Total) }}</div>
                            </div>
                        </div>
                        <div class="">
                              <button @click="Checkout()" :disabled="showPreloader" :class="{'bg-gray-300' :!this.$store.state.cart.cartItems.length|| !this.$store.state.cart.containers.length ,'bg-brand  shadow-lg' : this.$store.state.cart.cartItems.length || this.$store.state.cart.containers.length  }" class="flex justify-center w-full py-4 my-4 font-semibold text-black rounded shadow-lg" >
                                    <Preloader v-if="showPreloader"/>
                                    <span v-if="cartLenght">Proceed to Checkout</span>
                                </button>
                        </div>
                        <div   v-if="restaurant.bookingType === 'SCHEDULED'"  class="text-xs font-semibold text-gray-500">Expect your meal on or before {{ getSavedTimebelt.endTime }}</div>      
                    </div>  
                    
                </div>
                <div v-else class="flex items-center justify-center my-24 text-gray-400 ">
                    <div class="block">
                        <div class="flex justify-center" >
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-14 w-14" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
                            </svg>
                        </div>
                        <p class="mx-3 mt-3 text-center text-black">
                            Your cart is empty.
                        </p>
                    </div>
                </div>
                <div class="absolute right-0 w-full px-3 z-6 ">
                    <!-- <button @click="Checkout()" :disabled="showPreloader" :class="{'bg-gray-300' :!this.$store.state.cart.cartItems.length|| !this.$store.state.cart.containers.length ,'bg-brand  shadow-lg' : this.$store.state.cart.cartItems.length || this.$store.state.cart.containers.length  }" class="flex justify-center w-full py-4 my-4 font-semibold text-black rounded shadow-lg" >
                        <Preloader v-if="showPreloader"/>
                        <span v-if="cartLenght">Proceed to Checkout</span>
                    </button> -->
                </div>
            </div>  
        </div>
    </div>
    <Modal v-if="redirect" :show="true"> 
        <h1 class="text-xl font-semibold">Login your account</h1>
        <p class="mt-4 text-sm" >To complete your purchase, you are required to Log in/Sign up</p>
        <div class="flex justify-end mt-2 space-x-4">
            <button @click="closeModal" class="px-3 py-2 text-sm font-semibold bg-gray-200 rounded-sm" >Go Back</button>
            <button @click="redirectLogin" class="px-3 py-2 text-sm font-semibold rounded-sm bg-brand" >Continue</button>
        </div>
    </Modal>
  </div>
    <div class="z-999" v-if="MinimumModal"> 
        <Modal  :show="true"> 
            <h1 class="text-xl font-semibold">Add more item to cart</h1>
            <p class="mt-4 text-sm">Items in your cart are below minimum order value &#8358;{{ this.global.formatNumber(minimumOrderCost) }}
            </p>
            <div class="flex justify-end mt-6 space-x-4">
                <button @click="toggleMinimumModal" class="px-3 py-2 text-sm font-semibold bg-gray-200 rounded-sm" >Go Back</button>
            </div>
        </Modal>
    </div>
 
</template>

<script>

import FoodCart from "@/components/FoodCart.vue";
import ContainerCart from "@/components/ContainerCart.vue";
import Modal from "@/components/Modal.vue";
import moment from "moment";
import Preloader from "@/components/Preloader.vue"
export default {
    name:['MobileCart'],
    emits:['changeMobileTimebelt','closeNav','showContainerPopup'],
    props:[
        'restaurant','isInstant','minTime', 'maxTime','Total','cartLenght','minimumOrderCost','allowBookingPortion'
    ],
    components:{
        FoodCart,Modal,Preloader,ContainerCart
    },
    data(){
        return{
            redirect:false,
            Quantity: 1,
            cart : [],
            showPreloader:false,
            instant: 'scheduled',
            getTimebelt: "",
            MinimumModal:false,
            showModal:false,
            getCart:this.$store.state.cart,
            getSavedTimebelt: this.$store.getters.getTimebelt,
            getDeliveryDate: moment(new Date(this.$store.getters.getDate)).format('MMMM Do YYYY'),
            MinimumModal:false
        }
    },
    methods:{
        closeModal(){
            this.redirect = false;
            this.$emit('closeNav');   
        },
        redirectLogin(){
            this.$router.push({ name: 'CustomerSignIn', query: { from: 'checkout' } });
        },
        closeNav(){   
            this.$refs.cart.classList.add('translate-x-full')              
            setTimeout(() => {
                    this.$emit('closeNav');    
            }, 500);
        },
        async Checkout(){
            if(this.getCart.cartItems.length > 0 || this.getCart.containers.length > 0){
                let portionMeals = this.getCart.cartItems.filter(item => item.foodType == "PORTION");
                if(this.Total < this.minimumOrderCost){
                    this.toggleMinimumModal()
                }else if(portionMeals.length > 0 ){
                    this.$emit('showContainerPopup');
                    this.closeNav()
                }else if((this.getCart.cartItems.length > 0 || this.getCart.containers.length > 0) && this.$store.getters.getUser){
                    this.showPreloader = true;
                    try {
                        let res = await this.global.bookOrder() 
                        this.$store.dispatch('addOrder',JSON.stringify(res.data))
                        this.$router.push({name: 'Checkout'})

                    } catch (error) {
                        console.log(error)
                        this.showPreloader = false;
                    }

                }else if(!this.$store.getters.getUser){
                    this.$refs.cart.classList.add('translate-x-full') 
                    this.redirect = true;  
                }   
            } 
        },
        toggleMinimumModal(){
            this.MinimumModal = !this.MinimumModal
        },
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        changeTimebelt(){
            this.$emit('changeMobileTimebelt')
            this.closeNav();
        }, 
        toggleMinimumModal(){
            this.MinimumModal = !this.MinimumModal
        }
    },
    mounted(){
        setTimeout(() => {
            this.$refs.cart.classList.remove('translate-x-full') 
        }, 100);  
    },
}
</script>

<style>

</style>